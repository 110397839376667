import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { removeLoginInfo } from '../lib/auth';
import { resetUserInfo } from '../stores/user';

export default function useAuth() {
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    removeLoginInfo();
    dispatch(resetUserInfo());
  }, [dispatch]);

  return { handleLogout };
}

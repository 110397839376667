import { store } from '../stores';
import LoadStorage from './LoadStorage';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { configureChains, createClient, WagmiConfig, chain } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import KlaytnProvider from '../contexts/KlaytnContext';

const queryClient = new QueryClient();

const bscChain = {
  id: 56,
  name: 'BNB Smart Chain Mainnet',
  network: 'binance',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://bsc-dataseed1.binance.org/',
  },
  blockExplorers: {
    default: { name: 'BSCScan', url: 'https://bscscan.com/' },
  },
  testnet: false,
};

const bscTestnetChain = {
  id: 97,
  name: 'BNB Smart Chain Testnet',
  network: 'binanceTestnet',
  nativeCurrency: {
    decimals: 18,
    name: 'tBNB',
    symbol: 'tBNB',
  },
  rpcUrls: {
    default: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  },
  blockExplorers: {
    default: { name: 'BSCScan', url: 'https://testnet.bscscan.com/' },
  },
  testnet: true,
};

const { chains, provider, webSocketProvider } = configureChains(
  [
    chain.mainnet,
    chain.goerli,
    chain.polygon,
    chain.polygonMumbai,
    bscChain,
    bscTestnetChain,
  ],
  [
    jsonRpcProvider({
      rpc: (chain) => ({ http: chain.rpcUrls.default }),
    }),
    publicProvider(),
  ],
  { targetQuorum: 2 },
);

const client = createClient({
  autoConnect: false,
  provider,
  webSocketProvider,
  connectors: [new MetaMaskConnector({ chains })],
});

const AppProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <WagmiConfig client={client}>
        <KlaytnProvider>
          <QueryClientProvider client={queryClient}>
            <LoadStorage />
            {children}
          </QueryClientProvider>
        </KlaytnProvider>
      </WagmiConfig>
    </Provider>
  );
};

export default AppProvider;

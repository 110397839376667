import api from './index';

export const addWallet = ({ chain, address, sign }) =>
  api
    .post('/wallet', { chain, address, sign })
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });

export const deleteWallet = ({ seqWallet }) =>
  api
    .put('/wallet', { seq_wallet: seqWallet })
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });

export const getWalletList = () =>
  api
    .get('/wallet')
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });

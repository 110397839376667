import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import styles from './MyPage.module.sass';
import Profile from './Profile';
import Settings from './Settings';
import Card from '../../components/Card';
import Dropdown from '../../components/Dropdown';
import Filters from '../../components/Filters';
import Product from '../../components/Product';
import Follower from './Follower';

import Icon from '../../components/Icon';
import Tooltip from '../../components/Tooltip';
import { convertAddress } from '../../lib/util';
import { useAccount, useSignMessage } from 'wagmi';
import WalletList from './WalletList';
import { getAddWalletSignMessage } from '../../lib/sign-message';
import { useSelector } from 'react-redux';
import { addWallet, getWalletList } from '../../api/wallet';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { products } from '../../mocks/products';
import { followers } from '../../mocks/followers';

const navigation = [
  'Wallet',
  // 'Followers',
  // 'Following'
];
// const intervals = ['Most recent', 'Most new', 'Most popular'];

const MyPage = () => {
  const history = useHistory();
  const { activeNetwork } = useSelector(({ wallet }) => wallet);
  const { active, isUserLoading } = useSelector(({ user }) => user);

  const { address, isConnected } = useAccount();
  const {
    data: sign,
    isError,
    isLoading,
    isSuccess,
    signMessage,
  } = useSignMessage({
    message: getAddWalletSignMessage(address),
  });

  const {
    isLoading: isWalletListLoading,
    data: walletList,
    error: walletError,
    refetch: refetchWalletList,
  } = useQuery(['getWalletList'], getWalletList, {});

  const [activeIndex, setActiveIndex] = useState(0);
  // const [sorting, setSorting] = useState(intervals[0]);

  const isExistedWallet = useMemo(() => {
    if (walletList && walletList.data?.wallets) {
      return walletList.data.wallets.some(
        (wallet) => wallet.address === address,
      );
    }
    return false;
  }, [walletList, address]);

  const handleAddWalletClick = () => {
    signMessage();
  };

  useEffect(() => {
    if (!isUserLoading && !active) {
      history.replace('/sign-in');
    }
  }, [isUserLoading, active, history]);

  useEffect(() => {
    if (isSuccess && sign) {
      addWallet({ chain: activeNetwork, address, sign }).then(() => {
        refetchWalletList();
      });
    }
  }, [isSuccess, sign]);

  return (
    <>
      <div className={styles.shop}>
        <Card className={styles.card}>
          <Profile />
          <div className={styles.control}>
            <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            {/*<div className={styles.dropdownBox}>*/}
            {/*  <Dropdown*/}
            {/*    className={styles.dropdown}*/}
            {/*    classDropdownHead={styles.dropdownHead}*/}
            {/*    value={sorting}*/}
            {/*    setValue={setSorting}*/}
            {/*    options={intervals}*/}
            {/*    small*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<Filters*/}
            {/*  className={styles.filters}*/}
            {/*  title="Showing 9 of 32 products"*/}
            {/*>*/}
            {/*  <Settings />*/}
            {/*</Filters>*/}
          </div>
          <div className={styles.wrap}>
            {activeIndex === 0 && (
              <>
                <Card
                  className={cn(styles.card)}
                  title="Current Wallet"
                  classTitle="title-blue"
                >
                  <div className={styles.overview}>
                    <div className={styles.list}>
                      {address ? (
                        <div className={styles.item}>
                          <div
                            className={styles.icon}
                            style={{ backgroundColor: '#CABDFF' }}
                          >
                            <Icon name="payment" size="24" />
                          </div>
                          <div className={styles.details}>
                            <div className={styles.label}>account</div>
                            <div className={styles.counter}>
                              {convertAddress(address)}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.item}>
                          <p className={styles.text}>
                            Please connect wallet first!
                          </p>
                        </div>
                      )}
                    </div>
                    {!isExistedWallet && isConnected && (
                      <button
                        className={cn('button', styles.button)}
                        disabled={!isConnected}
                        onClick={handleAddWalletClick}
                      >
                        <Icon name="add" size="24" />
                        Add Wallet
                      </button>
                    )}
                  </div>
                </Card>
                {walletList &&
                  walletList.result &&
                  walletList.data?.wallets && (
                    <WalletList
                      items={walletList.data.wallets}
                      refetchWalletList={refetchWalletList}
                    />
                  )}
              </>
            )}
            {/*{activeIndex === 1 && (*/}
            {/*  <>*/}
            {/*    <div className={styles.followers}>*/}
            {/*      {followers.map((x, index) => (*/}
            {/*        <Follower*/}
            {/*          className={styles.follower}*/}
            {/*          item={x}*/}
            {/*          key={index}*/}
            {/*          followers*/}
            {/*        />*/}
            {/*      ))}*/}
            {/*    </div>*/}
            {/*    <div className={styles.foot}>*/}
            {/*      <button*/}
            {/*        className={cn('button-stroke button-small', styles.button)}*/}
            {/*      >*/}
            {/*        Load more*/}
            {/*      </button>*/}
            {/*    </div>*/}
            {/*  </>*/}
            {/*)}*/}
            {/*{activeIndex === 2 && (*/}
            {/*  <>*/}
            {/*    <div className={styles.followers}>*/}
            {/*      {followers.map((x, index) => (*/}
            {/*        <Follower*/}
            {/*          className={styles.follower}*/}
            {/*          item={x}*/}
            {/*          key={index}*/}
            {/*        />*/}
            {/*      ))}*/}
            {/*    </div>*/}
            {/*    <div className={styles.foot}>*/}
            {/*      <button*/}
            {/*        className={cn('button-stroke button-small', styles.button)}*/}
            {/*      >*/}
            {/*        Load more*/}
            {/*      </button>*/}
            {/*    </div>*/}
            {/*  </>*/}
            {/*)}*/}
          </div>
        </Card>
      </div>
    </>
  );
};

export default MyPage;

import React from 'react';
import styles from './UserInfo.module.sass';
import Overview from './Overview';
import TrafficChannel from './TrafficChannel';
import ActiveCustomers from './ActiveCustomers';
import ShareProducts from './ShareProducts';
import RefundRequests from '../../components/RefundRequests';
import TopDevice from './TopDevice';
import TopCountry from './TopCountry';
import Message from './Message';
import NewCustomer from './NewCustomer';

const UserInfo = () => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <Overview className={styles.card} />
        <ActiveCustomers className={styles.card} />
      </div>
    </div>
  );
};

export default UserInfo;

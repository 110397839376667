import { progress } from '../utils';

export const market = [
  {
    id: 0,
    product: 'Axie Infinity',
    category: 'Turn-base RPG',
    image: '/images/content/icon-axie.png',
    image2x: '/images/content/icon-axie@2x.png',
    status: true,
    earn: 98,
    roi: 3200,
    balance: 37.8,
    users: 79850,
    usersPercent: progress(),
    timesPercent: progress(),
    times: 111,
  },
];

export const released = [
  {
    id: 0,
    product: 'Bento Matte 3D Illustration',
    category: 'UI design kit',
    image: '/images/content/product-pic-1.jpg',
    image2x: '/images/content/product-pic-1@2x.jpg',
    price: 98,
    status: true,
    ratingValue: 4.8,
    ratingCounter: 87,
    sales: 3200,
    balance: 55.8,
    views: 47850,
    viewsPercent: progress(),
  },
  {
    id: 1,
    product: 'DSM - Geometry pattern',
    category: 'UI design kit',
    image: '/images/content/product-pic-2.jpg',
    image2x: '/images/content/product-pic-2@2x.jpg',
    price: 123,
    status: false,
    ratingValue: 4.6,
    ratingCounter: 127,
    sales: 4900,
    balance: 35.8,
    views: 32500,
    viewsPercent: progress(),
  },
  {
    id: 2,
    product: 'Node - Crypto iOS UI design kit',
    category: 'UI design kit',
    image: '/images/content/product-pic-3.jpg',
    image2x: '/images/content/product-pic-3@2x.jpg',
    price: 0,
    status: true,
    sales: 5600,
    balance: -15.8,
    views: 55500,
    viewsPercent: progress(),
  },
  {
    id: 3,
    product: 'TaskEz: Productivity App iOS UI Kit',
    category: 'UI design kit',
    image: '/images/content/product-pic-4.jpg',
    image2x: '/images/content/product-pic-4@2x.jpg',
    price: 33,
    status: false,
    ratingValue: 4.9,
    ratingCounter: 7,
    sales: 1100,
    balance: 19.2,
    views: 39500,
    viewsPercent: progress(),
  },
  {
    id: 4,
    product: 'Academe 3D Education Icons',
    category: 'UI design kit',
    image: '/images/content/product-pic-5.jpg',
    image2x: '/images/content/product-pic-5@2x.jpg',
    price: 45,
    status: true,
    ratingValue: 4.8,
    ratingCounter: 87,
    sales: 3200,
    balance: 55.8,
    views: 47850,
    viewsPercent: progress(),
  },
];

import React from 'react';
import cn from 'classnames';
import styles from './GamesDashboard.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import Overview from './Overview';
import ProductActivity from './ProductActivity';
import ProductViews from './ProductViews';
import Games from './Games';

const GamesDashboard = ({ games }) => {
  return (
    <>
      <div className={styles.section}>
        <Games games={games} />
        <Overview className={styles.card} />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default GamesDashboard;

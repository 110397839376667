import { useCallback, useEffect } from 'react';
import { removeLoginInfo } from '../lib/auth';
import { fetchUserInfo, resetUserInfo } from '../stores/user';
import { useDispatch } from 'react-redux';
import useAuth from './useAuth';

export default function useLoadStorage() {
  const dispatch = useDispatch();
  const { handleLogout } = useAuth();

  useEffect(() => {
    const sessionUser = localStorage.getItem('BRAG_ABOUT_USER_TOKEN');

    if (sessionUser) {
      dispatch(fetchUserInfo());
    } else {
      handleLogout();
    }
  }, [handleLogout]);
}

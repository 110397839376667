import React from 'react';
import cn from 'classnames';
import styles from './Profile.module.sass';
import Icon from '../../../components/Icon';

const socials = [
  {
    title: 'twitter',
    url: 'https://twitter.com/axieinfinity',
  },
  // {
  //   title: 'instagram',
  //   url: 'https://www.instagram.com/ui8net/',
  // },
  // {
  //   title: 'pinterest',
  //   url: 'https://www.pinterest.com/ui8m/',
  // },
];

const Profile = ({ game }) => {
  return (
    <div className={styles.profile}>
      <div className={styles.details}>
        <div className={styles.avatar}>
          <img src={game.image} alt="Avatar" />
        </div>
        <div className={styles.wrap}>
          <div className={cn('h4', styles.man)}>{game.title}</div>
          <div className={styles.info}>{game.category}</div>
        </div>
      </div>
      <div className={styles.contacts}>
        <div className={styles.socials}>
          {socials.map((x, index) => (
            <a
              className={styles.social}
              href={x.url}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <Icon name={x.title} size="24" />
            </a>
          ))}
        </div>
        <button
          className={cn('button', styles.button)}
          onClick={() => window.open('https://axieinfinity.com/')}
        >
          Visit
        </button>
      </div>
    </div>
  );
};

export default Profile;

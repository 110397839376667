import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from './Header.module.sass';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import Search from './Search';
import Messages from './Messages';
import Notification from './Notification';
import User from './User';
import Image from '../Image';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal';
import Card from '../Card';
import Checkbox from '../Checkbox';
import { useAccount, useConnect } from 'wagmi';
import { convertAddress } from '../../lib/util';
import { setActiveNetwork } from '../../stores/wallet';

const Header = ({ onOpen }) => {
  const dispatch = useDispatch();
  const { user, active } = useSelector(({ user }) => ({
    user: user.user,
    active: user.active,
  }));
  const { connect, connectors } = useConnect();
  const { address, isConnected } = useAccount();

  const [visible, setVisible] = useState(false);
  const [isOpenConnect, setIsOpenConnect] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  // TODO: 선택적 월렛 활성화
  // const [activeWallet, setActiveWallet] = useState([]);

  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  const handleOpenConnect = (openState) => () => {
    setIsOpenConnect(openState);
  };

  const handleCheckChange = () => {
    setIsChecked((prevState) => !prevState);
  };

  const handleNetworkClick = (network) => () => {
    setSelectedNetwork((prevState) => (prevState === network ? null : network));
  };

  useEffect(() => {
    if (isConnected) {
      setIsOpenConnect(false);
    }
    if (isConnected && selectedNetwork) {
      dispatch(setActiveNetwork(selectedNetwork));
    }
  }, [isConnected, selectedNetwork]);

  return (
    <>
      <header className={styles.header}>
        <button
          className={styles.burger}
          onClick={() => handleClick()}
        ></button>
        <Search className={cn(styles.search, { [styles.visible]: visible })} />
        <button
          className={cn(styles.buttonSearch, { [styles.active]: visible })}
          onClick={() => setVisible(!visible)}
        >
          <Icon name="search" size="24" />
        </button>
        <div className={styles.control} onClick={() => setVisible(false)}>
          {active && user && !isConnected && (
            <button
              className={cn('button', styles.button)}
              onClick={handleOpenConnect(true)}
            >
              <Icon name="payment" size="24" />
              <span>Connect Wallet</span>
            </button>
          )}
          {active && user && isConnected && address && (
            <span className={styles.address}>{convertAddress(address)}</span>
          )}
          {/*<Messages className={styles.messages} />*/}
          {/*<Notification className={styles.notification} />*/}
          {active && user && <User className={styles.user} user={user} />}
        </div>
        {!active && !user && (
          <div className={styles.btns}>
            <Link className={styles.link} to="/sign-in">
              Sign in
            </Link>
            {/*<Link className={cn('button', styles.button)} to="/sign-up">*/}
            {/*  Sign up*/}
            {/*</Link>*/}
          </div>
        )}
      </header>
      <Modal visible={isOpenConnect}>
        <Card
          className={styles.card}
          title="Connect Wallet"
          classTitle={cn('title-purple', styles.title)}
          classCardHead={styles.head}
        >
          <div className={styles.connectWallet}>
            <div className={styles.connectWalletStep}>
              <p>1. Accept Terms of Service and Privacy Policy</p>
              <div className={styles.acceptBox}>
                <Checkbox
                  className={styles.checkbox}
                  classCheckboxTick={styles.checkboxTick}
                  value={isChecked}
                  onChange={handleCheckChange}
                />
                <span>I read and accept</span>
              </div>
            </div>
          </div>
          <div className={styles.connectWallet}>
            <div className={styles.connectWalletStep}>
              <p>2. Choose network</p>
              <div className={styles.connectWalletList}>
                <button
                  className={cn(styles.connectWalletListItem, {
                    [styles.inactive]: selectedNetwork !== 'ethereum',
                  })}
                  onClick={handleNetworkClick('Ethereum')}
                  disabled={!isChecked}
                >
                  <img src="/images/icons/icon-ethereum.svg" alt="ethereum" />
                  <p>Ethereum</p>
                </button>
                <button
                  className={cn(styles.connectWalletListItem, {
                    [styles.inactive]: selectedNetwork !== 'polygon',
                  })}
                  onClick={handleNetworkClick('Polygon')}
                  disabled={!isChecked}
                >
                  <img src="/images/icons/icon-polygon.svg" alt="polygon" />
                  <p>Polygon</p>
                </button>
              </div>
            </div>
          </div>
          <div className={styles.connectWallet}>
            <div className={styles.connectWalletStep}>
              <p>3. Choose wallet</p>
              <div className={styles.connectWalletList}>
                {connectors.map((connector) => (
                  <button
                    className={cn(styles.connectWalletListItem)}
                    disabled={
                      !connector.ready || !selectedNetwork || !isChecked
                    }
                    key={connector.id}
                    onClick={() => connect({ connector })}
                  >
                    <img
                      src={`/images/icons/icon-${connector.name.toLowerCase()}.svg`}
                      alt="metamask"
                    />
                    <p>{connector.name}</p>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default Header;

import React, { useState } from 'react';
import cn from 'classnames';
import styles from './BannerItem.module.sass';
import Control from './Control';
import Icon from '../Icon';
import Checkbox from '../Checkbox';

const BannerItem = ({
  className,
  item,
  value,
  onChange,
  released,
  withoutCheckbox,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    onChange();
    setVisible(!visible);
  };

  return (
    <div
      className={cn(styles.product, className, { [styles.active]: visible })}
    >
      <div className={styles.preview}>
        {!withoutCheckbox && (
          <Checkbox
            className={styles.checkbox}
            classCheckboxTick={styles.checkboxTick}
            value={value}
            onChange={() => handleClick()}
          />
        )}
        <Control
          className={styles.control}
          title={item.product}
          link={item.link}
        />
        <img
          srcSet={item.image2x ? `${item.image2x} 2x` : undefined}
          src={item.image}
          alt="Product"
        />
      </div>
    </div>
  );
};

export default BannerItem;

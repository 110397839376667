/* eslint-disable no-undef */
import React, { useEffect, useCallback } from 'react';
import cn from 'classnames';
import styles from './SignIn.module.sass';
import { use100vh } from 'react-div-100vh';
import { Link, useHistory } from 'react-router-dom';
import TextInput from '../../components/TextInput';
import Image from '../../components/Image';
import { onLogin } from '../../lib/auth';
import { useDispatch } from 'react-redux';
import { fetchUserInfo } from '../../stores/user';
import useAuth from '../../hooks/useAuth';

const SignIn = () => {
  const history = useHistory();
  const heightWindow = use100vh();

  const dispatch = useDispatch();
  const { handleLogout } = useAuth();

  const handleCredentialResponse = useCallback(
    async (response) => {
      try {
        await onLogin({ client: 'google', token: response.credential });
        dispatch(fetchUserInfo());
        history.replace('/');
      } catch {
        handleLogout();
      }
    },
    [dispatch, history, handleLogout],
  );

  useEffect(() => {
    const token = localStorage.getItem('BRAG_ABOUT_USER_TOKEN');
    if (token) {
      history.replace('/');
    }
  }, [history]);

  useEffect(() => {
    google.accounts.id.initialize({
      client_id:
        '306630641635-0mu72imkoephvtgkt523savcse7120j1.apps.googleusercontent.com',
      callback: handleCredentialResponse,
    });
    google.accounts.id.renderButton(
      document.getElementById('buttonDiv'),
      { theme: 'outline', size: 'large', shape: 'pill' }, // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog
  }, [handleCredentialResponse]);

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo192.png"
            srcDark="/images/logo192.png"
            alt="brand"
          />
        </Link>
        <div className={cn('h2', styles.title)}>Sign in</div>
        <div className={styles.head}>
          <div className={styles.subtitle}>Sign in with Open account</div>
          <div className={styles.btns} id="buttonDiv">
            <button className={cn('button-stroke', styles.button)}>
              <img src="/images/content/google.svg" alt="Google" />
              Sign in with Google
            </button>
            {/*<button className={cn("button-stroke", styles.button)}>*/}
            {/*  <Image*/}
            {/*    className={styles.pic}*/}
            {/*    src="/images/content/apple-dark.svg"*/}
            {/*    srcDark="/images/content/apple-light.svg"*/}
            {/*    alt="Apple"*/}
            {/*  />*/}
            {/*  Apple ID*/}
            {/*</button>*/}
          </div>
        </div>
        <div className={styles.body}>
          {/*<div className={styles.subtitle}>Or continue with email address</div>*/}
          {/*<TextInput*/}
          {/*  className={styles.field}*/}
          {/*  name="email"*/}
          {/*  type="email"*/}
          {/*  placeholder="Your email"*/}
          {/*  required*/}
          {/*  icon="mail"*/}
          {/*/>*/}
          {/*<TextInput*/}
          {/*  className={styles.field}*/}
          {/*  name="password"*/}
          {/*  type="password"*/}
          {/*  placeholder="Password"*/}
          {/*  required*/}
          {/*  icon="lock"*/}
          {/*/>*/}
          {/*<button className={cn('button', styles.button)}>Sign in</button>*/}
          <div className={styles.note}>
            This site is protected by reCAPTCHA and the Google Privacy Policy.
          </div>
          {/*<div className={styles.info}>*/}
          {/*  Don’t have an account?{' '}*/}
          {/*  <Link className={styles.link} to="/sign-up">*/}
          {/*    Sign up*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default SignIn;

import { auth } from '../../api/user';

export const onLogin = async ({ client, token }) => {
  const response = await auth({
    client,
    token,
  });

  const result = { token: null };

  if (!response.result) {
    throw new Error(`code(-1)`);
  }

  if (response.result) {
    const { token } = response.data;
    if (!token) {
      throw new Error('Internal server error');
    }
    localStorage.setItem('BRAG_ABOUT_USER_TOKEN', token);

    return { token };
  }

  return result;
};

export const removeLoginInfo = () => {
  localStorage.removeItem('BRAG_ABOUT_USER_TOKEN');
};

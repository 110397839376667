import axios from 'axios';
import { removeLoginInfo } from '../lib/auth';

const baseURL = process.env.REACT_APP_API_HOST;

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('BRAG_ABOUT_USER_TOKEN');
  const nextConfig = config;
  if (token) {
    nextConfig.headers.Authorization = `Bearer ${token}`;
  }

  return nextConfig;
});

api.interceptors.response.use(
  (response) => {
    const { result, msg } = response.data;
    if (!result && msg === 'auth fail') {
      removeLoginInfo();
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default api;

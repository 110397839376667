import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeNetwork: null,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setActiveNetwork: (state, action) => {
      state.activeNetwork = action.payload;
    },
    resetUserInfo: () => initialState,
  },
});

export const { setActiveNetwork } = walletSlice.actions;

export default walletSlice.reducer;

import React, { useState } from 'react';
import styles from './Transactions.module.sass';
import cn from 'classnames';
import { numberWithCommas } from '../../../utils.js';
import Card from '../../../components/Card';
import Dropdown from '../../../components/Dropdown';
import Loader from '../../../components/Loader';

// date
import { transactions } from '../../../mocks/transactions';

const intervals = ['Last 30 days', 'Last 20 days', 'Last 10 days'];

const Transactions = ({ className, users }) => {
  const [sorting, setSorting] = useState(intervals[0]);

  return (
    <Card
      className={cn(styles.card, className)}
      classCardHead={styles.head}
      title="Transactions"
      classTitle={cn('title-blue', styles.title)}
      head={
        <>
          {/*<Dropdown*/}
          {/*  className={styles.dropdown}*/}
          {/*  classDropdownHead={styles.dropdownHead}*/}
          {/*  value={sorting}*/}
          {/*  setValue={setSorting}*/}
          {/*  options={intervals}*/}
          {/*  small*/}
          {/*/>*/}
          <button
            className={cn('button-small', styles.button)}
            onClick={() => window.open('https://forms.gle/axke2a5PStK5cHVd8')}
          >
            Add Transaction
          </button>
        </>
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Address</div>
            <div className={styles.col}>Type</div>
            <div className={styles.col}>Price</div>
            <div className={styles.col}>memo</div>
          </div>
          {users.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>
                <div className={styles.label}>Date</div>
                {'2022-11-08 02:01'}
              </div>

              <div className={styles.col}>
                <div className={styles.product}>{x.address}</div>
                {/*<div className={styles.invoice}>{x.invoice}</div>*/}
              </div>

              <div className={styles.col}>
                <div className={styles.details}>
                  <div className={styles.product}>{x.address}</div>
                  {/*<div className={styles.invoice}>{x.invoice}</div>*/}
                </div>
                {x.type === 'Earn' ? (
                  <div
                    className={cn(
                      { 'status-green-dark': x.type === 'Earn' },
                      styles.status,
                    )}
                  >
                    Earn
                  </div>
                ) : (
                  <div
                    className={cn(
                      { 'status-red-dark': x.type !== 'Earn' },
                      styles.status,
                    )}
                  >
                    Spend
                  </div>
                )}
              </div>

              <div className={styles.col}>
                <div className={styles.label}>Price</div>
                {parseFloat(x.price) > 0 ? (
                  <div>
                    +${numberWithCommas(parseFloat(x.price).toFixed(2))}
                  </div>
                ) : (
                  <div className={styles.negative}>
                    -${(numberWithCommas(parseFloat(x.price)) * -1).toFixed(2)}
                  </div>
                )}
              </div>

              <div className={styles.col}>
                <div className={styles.label}>Memo</div> -
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.foot}>
        <button className={cn('button-stroke button-small', styles.button)}>
          <Loader className={styles.loader} />
          <span>Load more</span>
        </button>
      </div>
    </Card>
  );
};

export default Transactions;

import React, { useState } from 'react';
import styles from './Row.module.sass';
import cn from 'classnames';
import Checkbox from '../../../../../components/Checkbox';
import Balance from '../../../../../components/Balance';
import ModalProduct from '../../../../../components/ModalProduct';
import Control from '../../Control';

import { numberWithCommas } from '../../../../../utils.js';
import { Link } from 'react-router-dom';

const Row = ({ item, value, onChange, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <Link className={styles.item} to={`/detail/${item.seq_dapps}`}>
            <div className={styles.preview}>
              <img
                srcSet={item.image2x ? `${item.image2x} 2x` : undefined}
                src={item.image}
                alt="Game"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.title}</div>
              <div className={styles.wrap}>
                {/*<div className={styles.price}>${item.price}</div>*/}
                <div className={styles.category}>{item.category}</div>
              </div>
            </div>
          </Link>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>User</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {item.owners > 999
                ? `${(item.owners / 1000).toFixed(0)}k`
                : item.owners}
            </div>
            <div className={styles.line}>
              <div
                className={styles.progress}
                style={{
                  backgroundColor: '#2A85FF',
                  width: item.usersPercent,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>ROI</div>
          <div className={styles.sales}>
            <div className={styles.number}>{numberWithCommas(item.roi)}</div>
            {/*<Balance className={styles.balance} value={item.balance} />*/}
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Earn</div>${item.earn}
        </div>
        <div className={styles.col}>
          <div className={styles.label}>User</div>
          <div className={styles.box}>
            <div className={styles.number}>{item.times}</div>
            <div className={styles.line}>
              <div
                className={styles.progress}
                style={{
                  backgroundColor: '#8E59FF',
                  width: item.timesPercent,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;

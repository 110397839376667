import api from '.';

/**
 * [POST] auth user
 * @param {string} client OAuth Provider
 * @param {string} token JWT
 */
export const auth = ({ client, token }) =>
  api
    .post('/auth', {
      client,
      token,
    })
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });

/**
 * [GET] user info
 */
export const getUser = () =>
  api
    .get('/user')
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });

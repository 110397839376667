import React, { useState } from 'react';
import cn from 'classnames';
import styles from './GameDetail.module.sass';
import Profile from './Profile';
import Settings from './Settings';
import Card from '../../components/Card';
import Dropdown from '../../components/Dropdown';
import Filters from '../../components/Filters';
import Product from '../../components/Product';
import Follower from './Follower';

// data
import { products } from '../../mocks/products';
import { followers } from '../../mocks/followers';
import EarningOverview from '../Earning/Overview';
import UserInfo from '../UserInfo';
import Transactions from '../Statements/Transactions';
import { useRouteMatch } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import api from '../../api';

const navigation = ['Products', 'Followers', 'Following'];
const intervals = ['Most recent', 'Most new', 'Most popular'];

const GameDetail = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(intervals[0]);

  const route = useRouteMatch();

  const { isLoading, data, error } = useQuery(
    ['detail'],
    () => api.get(`/dapp/${route.params.game}`).then((res) => res.data),
    {},
  );

  console.log(data);

  return (
    <>
      <div className={styles.shop}>
        <div className={styles.background}>
          <img src="/images/content/bg-shop.jpg" alt="Background" />
        </div>
        <Card className={styles.card}>
          {data?.data?.dapp && <Profile game={data.data.dapp} />}
          <div className={styles.control}>
            <EarningOverview />
          </div>
          <UserInfo />
          {data?.data?.users && <Transactions users={data.data.users} />}
        </Card>
      </div>
    </>
  );
};

export default GameDetail;

import React from 'react';
import cn from 'classnames';
import styles from './WalletList.module.sass';
import Card from '../../../components/Card';
import { convertAddress } from '../../../lib/util';
import { deleteWallet } from '../../../api/wallet';

const WalletList = ({ items = [], className, refetchWalletList }) => {
  const handleRemoveWalletClick = (seqWallet) => async () => {
    await deleteWallet({ seqWallet });
    refetchWalletList();
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title="My Wallet"
      classTitle="title-green"
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Address</div>
            <div className={styles.col}>Chain</div>
            <div className={styles.col} />
          </div>
          {items.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>{convertAddress(x.address)}</div>

              <div className={styles.col}>
                <div
                  className={cn(
                    { 'status-blue': x.chain === 'Ethereum' },
                    { 'status-purple': x.chain === 'Polygon' },
                    styles.status,
                  )}
                >
                  {x.chain}
                </div>
              </div>
              <div className={styles.col}>
                <button
                  className={cn(
                    'button-stroke-red button-small',
                    styles.button,
                  )}
                  onClick={handleRemoveWalletClick(x.seq_wallet)}
                >
                  Remove Wallet
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default WalletList;

import React, { useState } from 'react';
import styles from './Games.module.sass';
import cn from 'classnames';
import Card from '../../../components/Card';
import Form from '../../../components/Form';
import Dropdown from '../../../components/Dropdown';
import Game from './Game';
import Table from './Table';

// data
import { traffic } from '../../../mocks/traffic';
import { viewers } from '../../../mocks/viewers';
import { market } from '../../../mocks/market';

const indicatorsTraffic = [
  {
    title: 'Market',
    color: '#FFBC99',
  },
  {
    title: 'Social media',
    color: '#CABDFF',
  },
  {
    title: 'Direct',
    color: '#B5E4CA',
  },
  {
    title: 'UI8',
    color: '#B1E5FC',
  },
  {
    title: 'Others',
    color: '#FFD88D',
  },
];

const indicatorsViewers = [
  {
    title: 'Followers',
    color: '#B5E4CA',
  },
  {
    title: 'Others',
    color: '#CABDFF',
  },
];

const Games = ({ games }) => {
  const navigation = ['Market', 'Traffic sources', 'Viewers'];

  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState('');

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <Card
      className={styles.card}
      title="Ranking"
      classTitle={cn('title-purple', styles.title)}
      classCardHead={styles.head}
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          {activeTab === navigation[0] && <Game items={games} />}
        </div>
      </div>
    </Card>
  );
};

export default Games;

import React from 'react';
import cn from 'classnames';
import styles from './P2EMain.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import Overview from './Overview';
import PopularProducts from '../../components/PopularProducts';
import Comments from './Comments';
import RefundRequests from '../../components/RefundRequests';
import ProTips from './ProTips';
import MoreCustomers from './MoreCustomers';
import ProductViews from './ProductViews';
import Games from '../GamesDashboard/Games';
import Card from '../../components/Card';
import BannerItem from '../../components/BannerItem';
import { products } from '../../mocks/products';
import Product from '../../components/Product';
import GamesDashboard from '../GamesDashboard';
import { useQuery } from '@tanstack/react-query';
import api from '../../api';

const P2EMain = () => {
  const { isLoading, data, error } = useQuery(
    ['home'],
    () => api.get('/home').then((res) => res.data),
    {},
  );

  return (
    <>
      <div className={styles.row}>
        <Card className={styles.box}>
          {products.slice(0, 3).map((x, index) => (
            <BannerItem
              className={styles.banner}
              item={x}
              key={index}
              withoutCheckbox
            />
          ))}
        </Card>
      </div>
      <div className={styles.row}>
        {data?.data.dapps && <GamesDashboard games={data.data.dapps} />}
      </div>
      <TooltipGlodal />
    </>
  );
};

export default P2EMain;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUser } from '../api/user';
import { removeLoginInfo } from '../lib/auth';

const initialState = {
  active: false,
  isUserLoading: true,
  user: null,
  wallets: [],
  awards: [],
};

export const fetchUserInfo = createAsyncThunk(
  'user/fetchUserInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUser();
      if (response.result && response.data) {
        return response.data;
      }
      throw new Error('Failed to get user info');
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    readyToUpdate: (state) => {
      state.active = true;
    },
    setUserLoading: (state, action) => {
      state.isUserLoading = action.payload;
    },
    resetUserInfo: () => ({ ...initialState, isUserLoading: false }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.pending, (state) => {
      state.isUserLoading = true;
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.isUserLoading = false;
      state.active = true;
      state.user = action.payload.user;
      state.wallets = action.payload.wallets;
      state.awards = action.payload.awards;
    });
    builder.addCase(fetchUserInfo.rejected, (state) => {
      state.active = false;
      state.isUserLoading = false;
      removeLoginInfo();
    });
  },
});

export const { readyToUpdate, setUserLoading, resetUserInfo } =
  userSlice.actions;

export default userSlice.reducer;
